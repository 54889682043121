import React from 'react';
import { Outlet } from 'react-router-dom';

import 'src/styles/index.scss';
import Head from 'src/components/common/Head';

const PageLayout = () => {
  return (
    <>
      <Head />
      <Outlet />
    </>
  );
};

export default PageLayout;
