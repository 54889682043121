import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ScreenType, PopupType } from 'src/types/commonTypes';

import * as actions from './actions';

export type ConfigState = actions.Init;

const initialState: ConfigState = {
  quiz: null,
  isLoading: true,
  isReady: false,
  lead: {
    _id: '123',
    quizName: 'quiz-1',
    answers: {},
    questionsOrder: [],
    answersOrder: {},
  },
  seo_og: {
    seo: {
      _id: 'seo',
      mainPage: {
        title: '',
        description: '',
      },
    },
    og: {
      _id: 'og',
      mainPage: {
        title: '',
        description: '',
        imageUrl: '',
      },
    },
  },
  stepIndex: 0,
  screen: 'welcome',
  popup: null,
};

export const config = createSlice({
  name: 'config',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<Pick<actions.Init, 'seo_og'>>) => {
      state.seo_og = action.payload.seo_og;
    },
    setAnswer: (state, action: PayloadAction<Record<string, Array<string>>>) => {
      const answers = { ...state.lead.answers };

      const id = Object.keys(action.payload)[0];
      answers[id] = action.payload[id];

      state.lead.answers = answers;
      // state.stepIndex = Object.keys(answers).length;
    },
    changeStep: (state, action: PayloadAction<number>) => {
      state.stepIndex = action.payload < 0 ? 0 : action.payload;
    },
    setScreen: (state, action: PayloadAction<ScreenType>) => {
      state.screen = action.payload;
    },
    setPopup: (state, action: PayloadAction<PopupType>) => {
      state.popup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actions.getLeadInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(actions.getLeadInfo.fulfilled, (state, action) => {
      if (action.payload.success) {
        const { lead, quiz } = action.payload.data;
        const answersLength = Object.keys(lead.answers).length;
        state.lead = lead;
        state.quiz = quiz;
        state.stepIndex = answersLength;

        state.isReady = true;
        state.isLoading = false;

        if (answersLength > 0 && answersLength < lead.questionsOrder.length) {
          state.screen = 'questions';
        } else if (answersLength === lead.questionsOrder.length) {
          state.screen = 'final';
        } else {
          state.screen = 'welcome';
        }
      }

      state.isLoading = false;
    });
    builder.addCase(actions.getLeadInfo.rejected, (state) => {
      state.isLoading = false;
      state.isReady = false;
    });
  },
});

export const { init, setAnswer, changeStep, setScreen, setPopup } = config.actions;
export default config.reducer;
