import React from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { PATH_TO_QUIZ_NAME } from '@api/models/Leads';
import { useQuestionsData } from 'src/hooks/useQuestionsData';
import HeaderSocials from '../HeaderSocials';
import logo2Src from 'src/assets/header/logo2.svg';
import ynetLogoSrc from 'src/assets/header/ynet-logo.svg';
import css from './Header.module.scss';

type LogoInfo = {
  link: string;
  src: string;
};

const logos: Array<LogoInfo> = [
  {
    link: '/',
    src: ynetLogoSrc,
  },
  {
    link: '/',
    src: logo2Src,
  },
];

const Header = () => {
  const { screen } = useQuestionsData();
  const params = useParams<{ quizName: string }>();
  const quizName = PATH_TO_QUIZ_NAME[params.quizName!];
  const isQuiz1 = quizName === 'quiz-1';
  return (
    <header className={cn(screen === 'questions' && css.absolute)}>
      <div className={css.headerWrapper}>
        <div className={css.logos}>
          {logos.map((logo, index) => {
            return (
              <a href={logo.link} key={index}>
                <img src={logo.src} alt="logo" />
              </a>
            );
          })}
        </div>
        <div className={css.text}>
          <p className={cn(css.title, (screen === 'final' || screen === 'questions' || isQuiz1) && css.show)}>
            סקר השירות הגדול של ישראל
          </p>
          {(screen === 'final' || isQuiz1) && <p className={css.finalText}>בקרב קוראי ynet וידיעות אחרונות</p>}
        </div>
        <HeaderSocials />
      </div>
    </header>
  );
};

export default Header;
