import React from 'react';
import { useSelector } from 'react-redux';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import ouibounce from 'ouibounce';
import { useResponsive } from 'src/hooks/responsive';
import { useQuestionsData } from 'src/hooks/useQuestionsData';
import { transitionClassNames } from 'src/utils/css-transition-classnames';
import { selectLeadData } from 'src/store/config/selectors';
import { useAppDispatch } from 'src/hooks/store';
import { setPopup, setScreen } from 'src/store/config';

import Progress from 'src/components/common/Progress';
import SingleChoice from '../QuestionsTypes/SingleChocie';
import MultipleChoice from '../QuestionsTypes/MultipleChoice';

import { leads as leadsApi } from 'src/store/api/leads';
import { Params as UpdateParams } from '@api/routes/leads/update/params';

import bgSrc from 'src/assets/questions/bg.jpg';
import bgMobSrc from 'src/assets/questions/bg-mob.jpg';

import css from './QuestionsWrapper.module.scss';

const QuestionsWrapper = () => {
  const [isMobile] = useResponsive('MOBILE');
  const { questions, step, nextStep } = useQuestionsData();
  const leadData = useSelector(selectLeadData);
  const dispatch = useAppDispatch();

  const [updateAnswers, updateAnswersState] = leadsApi.endpoints.updateAnswers.useMutation();

  const onSubmitHandler = (params: UpdateParams & { quizName: string }) => {
    updateAnswers(params);
  };

  const renderQuestion = () => {
    const currentQuestion = questions.at(step);

    if (currentQuestion) {
      if (currentQuestion.choiceAmount > 1) {
        return <MultipleChoice info={currentQuestion} onSubmitHandler={onSubmitHandler} step={step} />;
      } else {
        return <SingleChoice info={currentQuestion} onSubmitHandler={onSubmitHandler} step={step} />;
      }
    }
  };

  React.useEffect(() => {
    const _ouibounce = ouibounce(false, {
      aggressive: true,
      callback: () => {
        dispatch(setPopup({ type: 'quit', text: `בטוח שברצונך לצאת? \n רק כמה שאלות וסיימנו` }));
      },
    });

    return () => {
      _ouibounce.disable();
    };
  }, []);

  React.useEffect(() => {
    if (updateAnswersState.data?.success) {
      if (leadData.questionsOrder.length - 1 === step) {
        dispatch(setScreen('final'));
      } else {
        nextStep();
      }
    }
  }, [updateAnswersState]);

  return (
    <div className={css.wrapper}>
      <div className={css.background} style={{ backgroundImage: `url(${isMobile ? bgMobSrc : bgSrc})` }}></div>
      <Progress />
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={step}
          timeout={500}
          classNames={contentTransitionClassNames}
          appear
          mountOnEnter
          unmountOnExit
        >
          {renderQuestion()}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

const contentTransitionClassNames = transitionClassNames(css, 'questionsTransition');

export default QuestionsWrapper;
