import React from 'react';
import { useSelector } from 'react-redux';

import { Question } from '@api/routes/survey/helpers/get-config-data';

import { selectQuizData, selectLeadData, selectStepIndex, selectScreen } from 'src/store/config/selectors';
import { changeStep } from 'src/store/config';

import { useAppDispatch } from './store';

export function useQuestionsData() {
  const dispatch = useAppDispatch();
  const quizData = useSelector(selectQuizData);
  const leadData = useSelector(selectLeadData);
  const step = useSelector(selectStepIndex);
  const screen = useSelector(selectScreen);

  const backToPreviousStep = React.useCallback(() => {
    dispatch(changeStep(step - 1));
  }, [step]);

  const nextStep = React.useCallback(() => {
    dispatch(changeStep(step + 1));
  }, [step]);

  const { questions, id, length } = React.useMemo<{
    questions: Array<Question>;
    id: string;
    length: number;
  }>(() => {
    const questionsData: Array<Question> = [];
    leadData.questionsOrder.forEach((item) => {
      const questionData = quizData?.find((i) => i._id === item);
      const answersIds = leadData.answersOrder[item];
      const answers: Question['answers'] = [];

      if (questionData) {
        answersIds.forEach((id) => {
          const answer = questionData.answers.find((ans) => ans._id === id);
          if (answer) answers.push(answer);
        });

        questionsData.push({ ...questionData, answers: answers });
      }
    });

    return {
      questions: questionsData,
      id: leadData._id,
      length: questionsData.length,
    };
  }, [quizData, leadData]);

  return { step, questions, screen, id, length, backToPreviousStep, nextStep };
}
