import React from 'react';
import cn from 'classnames';
import * as yup from 'yup';
import _ from 'lodash';
import { FormikProvider, Form, useFormik } from 'formik';
import { useParams } from 'react-router-dom';

import { selectLeadData } from 'src/store/config/selectors';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/store';
import { setAnswer, setPopup } from 'src/store/config/index';
import { useResponsive } from 'src/hooks/responsive';
import { dataLayerEvents } from 'src/utils/gtag';

import { useQuestionsData } from 'src/hooks/useQuestionsData';

import { PATH_TO_QUIZ_NAME, QUIZ_PATH } from '@api/models/Leads';
import { Question } from '@api/routes/survey/helpers/get-config-data';
import { Params as UpdateParams } from '@api/routes/leads/update/params';

import { analyticsData } from '../helpers';

import css from './MultipleChoice.module.scss';

interface Props {
  info: Question;
  onSubmitHandler: (params: UpdateParams & { quizName: string }) => void;
  step: number;
}

const MultipleChoice = ({ info, onSubmitHandler, step }: Props) => {
  const { backToPreviousStep } = useQuestionsData();
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isPopupShowedBefore, setIsPopupShowedBefore] = React.useState(false);
  const dispatch = useAppDispatch();
  const leadData = useSelector(selectLeadData);
  const [isMobile] = useResponsive('MOBILE');
  const params = useParams<{ quizName: string }>();
  const quizName = PATH_TO_QUIZ_NAME[params.quizName!];

  const isTwoColumns = info.columns === 2;
  const initialValues = {
    [`${info._id}`]: leadData.answers[info._id] || [],
  };

  React.useEffect(() => {
    const locationPath = window.location.href;

    if (quizName === 'quiz-1') {
      dataLayerEvents.pageView(`${step + 1} שאלה`, `${locationPath}/question${step + 1}`);
    }
    if (quizName === 'quiz-2') {
      dataLayerEvents.pageView(`${step + 1} שאלה`, `${locationPath}/question${step + 1}`);
    }

    window.scrollTo(0, 0);
  }, []);

  const formikContext = useFormik<Record<string, Array<string>>>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: yup
      .object()
      .shape({ [`${info._id}`]: yup.array().min(1, 'Must chose minimum one element').of(yup.string()) }),
    onSubmit: (values) => {
      const answers = Object.values(values)[0];

      /**
       * Show popup logic
       */
      if (!isPopupShowedBefore) {
        if (quizName === 'quiz-1') {
          const allResetIds = info.answers.filter((answer) => answer.resetOthersOnSelect).map((i) => i._id);
          const hasResetId = _.some(answers, (str) => _.includes(allResetIds, str));

          if (!hasResetId && answers.length < info.choiceAmount) {
            if (info.choiceAmount > 2) {
              if (answers.length === 1) {
                dispatch(
                  setPopup({
                    type: 'choice-info',
                    text: `בחרת תשובה אחת, ניתן לבחור עד ${info.choiceAmount} תשובות`,
                  }),
                );
              } else {
                dispatch(
                  setPopup({
                    type: 'choice-info',
                    text: `בחרת ${answers.length} תשובות, ניתן לבחור עד ${info.choiceAmount} תשובות`,
                  }),
                );
              }
            } else {
              dispatch(setPopup({ type: 'choice-info', text: `אפשר לבחור עוד תשובה אחת` }));
            }

            setIsPopupShowedBefore(true);
            return;
          }
        }
      }

      const { answersIndexes, answersTitles } = analyticsData(answers, info);

      dispatch(setAnswer(values));
      onSubmitHandler({
        questionId: info._id,
        answersIds: Object.values(values)[0],
        quizName: quizName,
      });
      setIsSubmitted(true);
      dataLayerEvents.question(info.title, answersIndexes, answersTitles);
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.currentTarget;
    const { reset } = e.target.dataset;
    const allResetIds = info.answers.filter((answer) => answer.resetOthersOnSelect).map((i) => i._id);
    let resetChecked = false;

    if (reset && checked) {
      formikContext.setFieldValue(info._id, [value]);
      return;
    }
    if (reset && !checked) {
      formikContext.setFieldValue(
        info._id,
        formikContext.values[`${info._id}`].filter((v: string) => v !== value),
      );
      return;
    }

    if (checked) {
      if (formikContext.values[`${info._id}`].length > info.choiceAmount - 1) return;

      const values = [...formikContext.values[`${info._id}`]];
      values.forEach((item) => {
        if (allResetIds.find((i) => i === item)) {
          resetChecked = true;
        }
      });

      if (resetChecked) {
        formikContext.setFieldValue(info._id, [value]);
        return;
      }

      formikContext.setFieldValue(info._id, [...formikContext.values[`${info._id}`], value]);
    } else {
      formikContext.setFieldValue(
        info._id,
        formikContext.values[`${info._id}`].filter((v: string) => v !== value),
      );
    }
  };

  const clickOnBack = () => {
    backToPreviousStep();
    dataLayerEvents.btnQuestionClick('לשאלה הקודמת');
  };

  const clickOnSubmit = () => {
    dataLayerEvents.btnQuestionClick('המשך');
  };

  const title = (step: number) => {
    if (quizName === 'quiz-1') {
      return info.title;
    } else {
      if (step + 1 === 14) {
        return info.title.replace('פיזיות', '<span>פיזיות</span>');
      }
      if (step + 1 === 15) {
        return info.title.replace('אונליין', '<span>אונליין</span>');
      }
      return info.title;
    }
  };

  if (!Array.isArray(formikContext.values[`${info._id}`])) return null;

  return (
    <FormikProvider value={formikContext}>
      <Form className={css.container}>
        {isMobile ? (
          <div className={css.mobileImage}>
            <img src={info.mobileImage} alt="" />
          </div>
        ) : (
          <div className={css.desktopImage}>
            <img src={info.desktopImage} alt="" />
          </div>
        )}
        <div className={cn(css.question)}>
          <div className={cn(css.questionI, isTwoColumns && css.twoColumns)}>
            <div
              className={cn(css.title, isTwoColumns && css.twoColumns)}
              dangerouslySetInnerHTML={{ __html: title(step) }}
            ></div>
            <div className={cn(css.answers, isTwoColumns && css.twoColumns)}>
              {info.answers.map((item) => {
                return (
                  <label key={item._id}>
                    <input
                      name={item._id}
                      type="checkbox"
                      value={item._id}
                      data-reset={item.resetOthersOnSelect}
                      checked={
                        formikContext.values[`${info._id}`]
                          ? formikContext.values[`${info._id}`].includes(item._id)
                          : false
                      }
                      onChange={handleChange}
                    />
                    <div className={css.answer}>
                      <div className={css.text}>{item.title}</div>
                    </div>
                  </label>
                );
              })}
            </div>
            <div className={css.buttons}>
              {/* {step !== 0 && (
                <button type="button" className={css.prev} onClick={clickOnBack}>
                  לשאלה הקודמת
                </button>
              )} */}
              <button
                type="submit"
                className={cn(
                  css.submit,
                  (formikContext.values[`${info._id}`].length === 0 || isSubmitted) && css.disabled,
                )}
                onClick={clickOnSubmit}
              >
                המשך
              </button>
            </div>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default MultipleChoice;
