import React from 'react';
import Icon from '../Icon';
import { SHARE_CONSTANTS } from 'src/constants/other';
import { share } from 'src/utils/share';
import { dataLayerEvents } from 'src/utils/gtag';
import { IS_DEVEL } from 'src/constants/other';
import css from './HeaderSocials.module.scss';

const HeaderSocials = () => {
  const clickOnShare = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const type = e.currentTarget.dataset.id;

    if (type === 'Facebook' || type === 'Twitter' || type === 'E-mail' || type === 'whatsApp') {
      share({ type });
    }

    if (type === 'Facebook' || type === 'Twitter' || type === 'E-mail' || type === 'whatsApp') {
      dataLayerEvents.shareClick(type);
    }
  };

  return (
    <div className={css.wrapper}>
      <div className={css.iconWrapper} data-id={SHARE_CONSTANTS.WHATS_APP} onClick={clickOnShare}>
        <Icon type="header_whatsapp" className={css.icon} />
      </div>
      <div className={css.iconWrapper} data-id={SHARE_CONSTANTS.FACEBOOK} onClick={clickOnShare}>
        <Icon type="header_fb" className={css.icon} />
      </div>
    </div>
  );
};

export default HeaderSocials;
