import React from 'react';
import { useSelector } from 'react-redux';
import { selectQuizData, selectLeadData } from 'src/store/config/selectors';
import { useQuestionsData } from 'src/hooks/useQuestionsData';
import { useResponsive } from 'src/hooks/responsive';

import css from './PreloadImages.module.scss';

const PreloadImages = () => {
  const quizData = useSelector(selectQuizData);
  const leadData = useSelector(selectLeadData);
  const { questions, step } = useQuestionsData();
  const [isMobile] = useResponsive('MOBILE');

  if (!quizData || !leadData) return null;

  return (
    <div className={css.imagesWrapper}>
      <div className={css.image}>
        {questions?.map((item, index) => {
          if (step === 0 && (step + 1 === index || index === step)) {
            return <img src={isMobile ? item.mobileImage : item.desktopImage} alt="" key={index} />;
          }
          if (step !== 0 && step !== quizData.length - 1) {
            if (step === index || step + 1 === index || step - 1 === index) {
              return <img src={isMobile ? item.mobileImage : item.desktopImage} alt="" key={index} />;
            }
          }
          if (step === quizData.length - 1 && (step - 1 === index || index === step)) {
            return <img src={isMobile ? item.mobileImage : item.desktopImage} alt="" key={index} />;
          }
        })}
      </div>
    </div>
  );
};

export default PreloadImages;
