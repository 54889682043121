import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import Head from 'src/components/common/Head';
import Header from 'src/components/common/Header';
import { getLeadInfo } from 'src/store/config/actions';
import { selectIsAppReady } from 'src/store/config/selectors';
import PreloadImages from 'src/components/common/PreloadImages';
import Popup from 'src/components/common/Popup';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';

import { PATH_TO_QUIZ_NAME } from '@api/models/Leads';

import Screens from './Screens';

const SEO_AND_OG: any = {
  'service-survey-1': {
    title: `סקר השירות הגדול של המדינה - חלק 1`,
    description: `עזרו לנו להבין מהי תמונת המצב של השירות בישראל? אילו דברים חשובים לכם/ן בשירות? ומה צריך עוד כדי לייצר חווית שירות מצוינת?`,
  },
  'service-survey-2': {
    title: `סקר השירות הגדול של המדינה - חלק 2`,
    description: `עזרו לנו להבין מהי תמונת המצב של השירות בישראל? אילו דברים חשובים לכם/ן בשירות? ומה צריך עוד כדי לייצר חווית שירות מצוינת?`,
  },
};

const Quiz = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{ quizName: string }>();
  const quizName = PATH_TO_QUIZ_NAME[params.quizName!];
  const isAppReady = useAppSelector(selectIsAppReady);
  const removePreloaderTimeoutRef = useRef<NodeJS.Timeout | number | null>(null);

  useEffect(() => {
    if (isAppReady) {
      removeRootPreloader();
    }
  }, [isAppReady]);

  useEffect(() => {
    dispatch(getLeadInfo({ quizName: quizName }));
  }, []);

  const removeRootPreloader = () => {
    const preloadingScreen: HTMLElement | null = document.querySelector('.main-preloader');

    if (preloadingScreen) {
      setTimeout(() => {
        preloadingScreen.style.opacity = '0';
        preloadingScreen.style.visibility = 'hidden';
        preloadingScreen.style.pointerEvents = 'none';
      }, 500);

      if (removePreloaderTimeoutRef.current) clearTimeout(removePreloaderTimeoutRef.current);
      removePreloaderTimeoutRef.current = setTimeout(() => {
        if (document && document.body) {
          document.body.removeChild(preloadingScreen);
        }
      }, 1500);
    }
  };

  return (
    <>
      {params.quizName && (
        <Head seo={SEO_AND_OG[params.quizName]} og={SEO_AND_OG[params.quizName]}>
          <link rel="canonical" href={`https://leumisurvey.ynet.co.il/${params.quizName}`} />
        </Head>
      )}
      <Header />
      {isAppReady && <Screens />}
      <PreloadImages />
      <Popup />
    </>
  );
};

export default Quiz;
