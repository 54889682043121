import React from 'react';
import cn from 'classnames';

import { useResponsive } from 'src/hooks/responsive';
import { dataLayerEvents } from 'src/utils/gtag';
import { useParams } from 'react-router-dom';

import { PATH_TO_QUIZ_NAME, QUIZ_PATH } from '@api/models/Leads';

import bgSrc from 'src/assets/final/bg.jpg';
import bgMobSrc from 'src/assets/final/bg-mob.jpg';

import FinalSocials from '../FinalSocials';

import css from './FinalScreen.module.scss';

const FinalScreen = () => {
  const [isMobile] = useResponsive('MOBILE');
  const params = useParams<{ quizName: string }>();
  const quizName = PATH_TO_QUIZ_NAME[params.quizName!];
  const subtitleText = `בזכותך נוכל להבין מה מעדיפים הישראלים ומהו שירות נוח <br/> תוצאות הסקר יתפרסמו בקרוב באתר ynet`;

  React.useEffect(() => {
    const locationPath = window.location.href;
    if (quizName === 'quiz-1') {
      dataLayerEvents.pageView('דף תודה', `${locationPath}/thank-you`);
    }
    if (quizName === 'quiz-2') {
      dataLayerEvents.pageView('דף תודה', `${locationPath}/thank-you`);
    }
    window.scrollTo(0, 0);
  }, []);

  const renderText = () => {
    if (quizName === 'quiz-1') {
      return (
        <div className={css.text}>
          <div className={cn(css.title, css.finished)}>
            תודה לכל המשתתפות והמשתתפים בסקר השירות הגדול של ישראל - חלק 1!
          </div>
          <div className={cn(css.subtitle, css.finished)}>
            בקרוב יעלה לאתר ynet חלקו השני של הסקר. נשמח להשתתפותכם גם שם
          </div>
        </div>
      );
    }
    return (
      <div className={css.text}>
        <div className={cn(css.title, css.finished)}>
          תודה לכל המשתתפות והמשתתפים בסקר השירות הגדול של ישראל – חלק 2!
        </div>
        <div className={cn(css.subtitle, css.finished)}>בקרוב יתפרסמו באתר ynet התוצאות המלאות של שני חלקי הסקר</div>
      </div>
    );
  };

  return (
    <div className={css.finalScreen}>
      <div className={css.background} style={{ backgroundImage: `url(${isMobile ? bgMobSrc : bgSrc})` }}></div>
      {renderText()}
      <FinalSocials />
    </div>
  );
};

export default FinalScreen;
