import React from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';

import { PATH_TO_QUIZ_NAME } from '@api/models/Leads';
import { useQuestionsData } from 'src/hooks/useQuestionsData';
import { useResponsive } from 'src/hooks/responsive';

import css from './Progress.module.scss';

const Progress = () => {
  const { length, step } = useQuestionsData();
  const params = useParams<{ quizName: string }>();
  const [isMobile] = useResponsive('MOBILE');
  const stepsPadding = isMobile ? 1 : 2;

  return (
    <div className={css.progressWrapper}>
      <div className={css.stepCounter}>
        <span>{step + 1}</span>/{length}
      </div>
      <div className={cn(css.stepIndicator, PATH_TO_QUIZ_NAME[params.quizName!] === 'quiz-1' ? css.quiz1 : css.quiz2)}>
        {[...Array(length).keys()].map((item, index) => {
          return (
            <div
              key={index}
              className={cn(css.step, step + 1 >= index + 1 && css.active)}
              style={{ width: `calc((100% / ${length}) - ${stepsPadding}px)` }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default Progress;
