// import { API_HOST } from 'src/utils/api';
// import { ROUTE as UPDATE_ROUTE, Result as UpdateResult } from '@api/routes/leads/update/params';

import api from '.';
import { ApiResponse } from '@api/types/api-response';
import { ROUTE as UPDATE_ROUTE, Params as UpdateParams, Result as UpdateResult } from '@api/routes/leads/update/params';

export const leads = api.injectEndpoints({
  endpoints: (builder) => ({
    updateAnswers: builder.mutation<ApiResponse<UpdateResult>, UpdateParams & { quizName: string }>({
      query: (params) => ({
        method: 'POST',
        url: UPDATE_ROUTE.replace(':quizName', params.quizName),
        body: params,
      }),
    }),
  }),
  overrideExisting: false,
});
