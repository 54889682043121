import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useParams } from 'react-router-dom';
import { transitionClassNames } from 'src/utils/css-transition-classnames';

import QuestionsWrapper from 'src/components/common/QuestionsWrapper';
import FinalScreen from 'src/components/common/FinalScreen';
import StartScreen from 'src/components/common/StartScreen';
import { useQuestionsData } from 'src/hooks/useQuestionsData';
import { ScreenType } from 'src/types/commonTypes';
import { PATH_TO_QUIZ_NAME } from '@api/models/Leads';

import css from './Screens.module.scss';

const Screens = () => {
  const { screen } = useQuestionsData();
  const params = useParams<{ quizName: string }>();
  const quizName = PATH_TO_QUIZ_NAME[params.quizName!];

  const renderScreen = (screen: ScreenType) => {
    switch (screen) {
      case 'questions':
        return <QuestionsWrapper />;
      case 'final':
        return <FinalScreen />;
      case 'welcome':
        return <StartScreen />;
    }
  };

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={screen}
          timeout={300}
          classNames={contentTransitionClassNames}
          appear
          mountOnEnter
          unmountOnExit
        >
          <FinalScreen />
        </CSSTransition>
      </SwitchTransition>
    </>
  );
};

const contentTransitionClassNames = transitionClassNames(css, 'stageTransition');

export default Screens;
