import React from 'react';
import cn from 'classnames';
import { PopupType } from 'src/types/commonTypes';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { setPopup } from 'src/store/config';
import { selectPopup } from 'src/store/config/selectors';
import Icon from '../Icon';

import css from './Popup.module.scss';

interface Props {
  onClose?: () => void;
}

const Popup = (props: Props) => {
  const { onClose } = props;
  const popup = useAppSelector(selectPopup);
  const dispatch = useAppDispatch();

  const onCloseClick = () => {
    if (onClose) {
      onClose();
    }

    dispatch(setPopup(null));
  };

  return (
    <div className={cn(css.popup, !popup && css.hidden)}>
      <div className={css.overlay} onClick={onCloseClick} />
      <div className={css.content}>
        <p>{popup?.text}</p>
        <button type="button" className={css.closeButton} onClick={onCloseClick}>
          <Icon type="close" />
        </button>
      </div>
    </div>
  );
};

export default Popup;
