import React from 'react';
import { Route, createRoutesFromElements } from 'react-router-dom';

import PageLayout from 'src/components/common/PageLayout';
import { QUIZ, ROOT } from 'src/constants/routes';

import Quiz from './components/pages/Quiz';

export const Routes = (
  <>
    <Route path={ROOT} element={<PageLayout />}>
      <Route path={QUIZ} element={<Quiz />} />
    </Route>
  </>
);

export const routes = createRoutesFromElements(Routes);
export default routes;
