import { api as config } from '@api/utils/api-request';

let API_HOST = '';

switch (process.env.ENV) {
  case 'production':
    API_HOST = `https://leumisurvey.ynet.co.il`; // TODO: change to custom url in future
    break;

  case 'development':
    API_HOST = `https://leumisurvey.ynet.co.il`;
    break;

  case 'local':
  default:
    API_HOST = `http://localhost:${process.env.API_PORT}`;
    break;
}

export { API_HOST };

export const api = config(API_HOST);
