import { RootState } from '..';

export const selectConfig = (state: RootState) => {
  return state.config;
};

export const selectQuizData = (state: RootState) => {
  return state.config.quiz;
};

export const selectLeadData = (state: RootState) => {
  return state.config.lead;
};

export const selectStepIndex = (state: RootState) => {
  return state.config.stepIndex;
};

export const selectScreen = (state: RootState) => {
  return state.config.screen;
};

export const selectIsAppReady = (state: RootState) => {
  return state.config.isReady && !state.config.isLoading;
};

export const selectPopup = (state: RootState) => {
  return state.config.popup;
};
