import { CAN_USE_DOM } from 'src/constants/other';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const dataLayerEvents = {
  pageView: (url: string, label: string) =>
    CAN_USE_DOM &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'virtualPageview',
      pageLocation: `${url}`,
      pageTitle: `${label}`,
    }),

  question: (title: string, answerIndex: string, answerText: string) =>
    CAN_USE_DOM &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'answer',
      question: `${title}`,
      answerIndex: `${answerIndex}`,
      answerText: `${answerText}`,
    }),

  btnQuestionClick: (title: string) =>
    CAN_USE_DOM &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'content_click',
      clickText: `${title}`,
    }),
  shareClick: (title: string) =>
    CAN_USE_DOM &&
    window.dataLayer &&
    window.dataLayer.push({
      event: 'content_share',
      social_network_name: `${title}`,
    }),
};
