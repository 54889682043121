import React from 'react';
import cn from 'classnames';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { FormikProvider, Form, useFormik } from 'formik';

import { setAnswer } from 'src/store/config/index';
import { useQuestionsData } from 'src/hooks/useQuestionsData';
import { useAppDispatch } from 'src/hooks/store';
import { useResponsive } from 'src/hooks/responsive';

import { dataLayerEvents } from 'src/utils/gtag';
import { selectLeadData } from 'src/store/config/selectors';
import { useSelector } from 'react-redux';

import { PATH_TO_QUIZ_NAME, QuizName, QUIZ_PATH } from '@api/models/Leads';
import { Question } from '@api/routes/survey/helpers/get-config-data';
import { Params as UpdateParams } from '@api/routes/leads/update/params';

import { IS_DEVEL } from 'src/constants/other';
import { analyticsData } from '../helpers';

import css from './SingleChoice.module.scss';

interface Props {
  info: Question;
  onSubmitHandler: (params: UpdateParams & { quizName: string }) => void;
  step: number;
}

const SingleChoice = ({ info, onSubmitHandler, step }: Props) => {
  const params = useParams<{ quizName: QuizName }>();
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const { backToPreviousStep } = useQuestionsData();
  const dispatch = useAppDispatch();
  const leadData = useSelector(selectLeadData);
  const [isMobile] = useResponsive('MOBILE');
  const isTwoColumns = info.columns === 2;
  const quizName = PATH_TO_QUIZ_NAME[params.quizName!];
  const initialValues = {
    [`${info._id}`]: leadData.answers[info._id] || [],
  };

  React.useEffect(() => {
    const locationPath = window.location.href;
    if (quizName === 'quiz-1') {
      dataLayerEvents.pageView(`${step + 1} שאלה`, `${locationPath}/question${step + 1}`);
    }
    if (quizName === 'quiz-2') {
      dataLayerEvents.pageView(`${step + 1} שאלה`, `${locationPath}/question${step + 1}`);
    }
    window.scrollTo(0, 0);
  }, []);

  const formikContext = useFormik<Record<string, Array<string>>>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: yup
      .object()
      .shape({ [`${info._id}`]: yup.array().min(1, 'Must chose minimum one element').of(yup.string()).required() }),
    onSubmit: (values) => {
      const answers = Object.values(values)[0];
      const { answersIndexes, answersTitles } = analyticsData(answers, info);

      dispatch(setAnswer(values));
      onSubmitHandler({
        questionId: info._id,
        answersIds: Object.values(values)[0],
        quizName: quizName,
      });
      setIsSubmitted(true);
      dataLayerEvents.question(info.title, answersIndexes, answersTitles);
    },
  });

  const title = (step: number) => {
    if (quizName === 'quiz-1') {
      return info.title;
    } else {
      if (step + 1 === 14) {
        return info.title.replace('פיזיות', '<span>פיזיות</span>');
      }
      if (step + 1 === 15) {
        return info.title.replace('אונליין', '<span>אונליין</span>');
      }
      return info.title;
    }
  };

  const clickOnBack = () => {
    backToPreviousStep();
    console.log('IS_DEVEL', IS_DEVEL);

    dataLayerEvents.btnQuestionClick('לשאלה הקודמת');
  };

  const clickOnSubmit = () => {
    dataLayerEvents.btnQuestionClick('המשך');
  };

  const radioChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    formikContext.setFieldValue(info._id, [value]);
  };

  if (!Array.isArray(formikContext.values[`${info._id}`])) return null;

  return (
    <FormikProvider value={formikContext}>
      <Form className={css.container}>
        {isMobile ? (
          <div className={css.mobileImage}>
            <img src={info.mobileImage} alt="" />
          </div>
        ) : (
          <div className={css.desktopImage}>
            <img src={info.desktopImage} alt="" />
          </div>
        )}

        <div className={cn(css.question)}>
          <div className={cn(css.questionI, isTwoColumns && css.twoColumns)}>
            <div
              className={cn(css.title, isTwoColumns && css.twoColumns)}
              dangerouslySetInnerHTML={{ __html: title(step) }}
            ></div>
            <div className={cn(css.answers, isTwoColumns && css.twoColumns)}>
              {info.answers.map((item) => {
                return (
                  <label key={item._id}>
                    <input
                      name={item._id}
                      type="radio"
                      value={item._id}
                      checked={
                        formikContext.values[`${info._id}`]
                          ? formikContext.values[`${info._id}`].includes(item._id)
                          : false
                      }
                      onChange={radioChangeHandler}
                    />
                    <div className={css.answer}>
                      <div className={css.text}>{item.title}</div>
                    </div>
                  </label>
                );
              })}
            </div>
            <div className={css.buttons}>
              {/* {step !== 0 && (
                <button type="button" className={css.prev} onClick={clickOnBack}>
                  לשאלה הקודמת
                </button>
              )} */}
              <button
                type="submit"
                className={cn(
                  css.submit,
                  ((formikContext.values && formikContext.values[`${info._id}`].length === 0) || isSubmitted) &&
                    css.disabled,
                )}
                onClick={clickOnSubmit}
              >
                המשך
              </button>
            </div>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default SingleChoice;
