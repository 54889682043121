import { Question } from '@api/routes/survey/helpers/get-config-data';

export const analyticsData = (
  answers: Array<string>,
  info: Question,
): { answersIndexes: string; answersTitles: string } => {
  const answersIndexes = answers
    .map((i) => {
      const realIndex = info.answers.findIndex((a) => i === a._id);
      if (realIndex >= 0) {
        return realIndex + 1;
      }
    })
    .join(',');
  const answersTitles = answers
    .map((i) => {
      const answer = info.answers.find((a) => i === a._id);
      if (answer) {
        return answer.title;
      }
    })
    .join(',');

  return {
    answersIndexes,
    answersTitles,
  };
};
