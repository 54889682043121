import { createAsyncThunk } from '@reduxjs/toolkit';

import { ROUTE as LEADS_GET, Result as GetResult } from '@api/routes/leads/get/params';
import { ApiResponse } from '@api/types';

import { api } from 'src/utils/api';
import { QuizName } from '@api/models/Leads';

export const getLeadInfo = createAsyncThunk<ApiResponse<GetResult>, { quizName: QuizName }, { rejectValue: string }>(
  'config/get-lead-info',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.sendRequest<GetResult>({
        url: LEADS_GET.replace(':quizName', data.quizName),
        method: 'GET',
      });

      if (response.success) {
        console.info('response', response);
        return response;
      }

      throw new Error(response.error.message as string);
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);
