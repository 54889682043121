import { buildCreateApi, coreModule, reactHooksModule, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import fetch from 'isomorphic-fetch';

import { SESSION as SESSION_COOKIE } from '@api/constants/cookies';
import { VARY_HEADER } from '@api/constants/other';
import { API_HOST } from 'src/utils/api';

const customCreateApi = buildCreateApi(coreModule(), reactHooksModule({ unstable__sideEffectsInRender: true }));

export const api = customCreateApi({
  reducerPath: 'api',
  tagTypes: ['LEAD'],
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: API_HOST,
      credentials: 'include',
      ...(typeof window !== 'undefined' && {
        fetchFn: fetch,
      }),
      prepareHeaders: (headers, { extra }) => {
        headers.set('Authorization', `Bearer ${(extra as any)?.cookies?.[SESSION_COOKIE]}`);
        headers.set('Vary', VARY_HEADER);
        return headers;
      },
    }),
    { maxRetries: 3 },
  ),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === 'HYDRATE') {
      return action.payload[reducerPath];
    }
  },
  endpoints: () => ({}),
});

export default api;
