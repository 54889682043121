import React from 'react';
import { useParams } from 'react-router-dom';
import { useResponsive } from 'src/hooks/responsive';
import { useAppDispatch } from 'src/hooks/store';
import { setScreen } from 'src/store/config';

import { PATH_TO_QUIZ_NAME } from '@api/models/Leads';

import { dataLayerEvents } from 'src/utils/gtag';

import bgSrc from 'src/assets/final/bg.jpg';
import bgMobSrc from 'src/assets/final/bg-mob.jpg';

import css from './StartScreen.module.scss';

const StartScreen = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isMobile] = useResponsive('MOBILE');
  const dispatch = useAppDispatch();
  const params = useParams<{ quizName: string }>();
  const quizNumber = PATH_TO_QUIZ_NAME[params.quizName!] === 'quiz-1' ? 1 : 2;

  React.useEffect(() => {
    const locationPath = window.location.href;
    if (PATH_TO_QUIZ_NAME[params.quizName!] === 'quiz-1') {
      dataLayerEvents.pageView('סקר לאומי', locationPath);
    }
    setIsLoaded(true);
  }, []);

  const cliskOnStartButton = () => {
    dispatch(setScreen('questions'));
  };

  if (!isLoaded) return null;
  return (
    <div className={css.startScreen}>
      <div className={css.background} style={{ backgroundImage: `url(${isMobile ? bgMobSrc : bgSrc})` }}></div>
      <div className={css.info}>
        <div className={css.title}>ברוכים הבאים לסקר השירות הגדול של ישראל!</div>
        {/* <div className={css.quizNumber}>
          <span>{`חלק ${quizNumber}`}</span>
        </div> */}

        <div className={css.subtitle}>
          מה דעתכם על תרבות השירות בישראל? מה העדפות השירות שלכם באונליין? ומה הופך שירות לנוח?
          <span>ענו על הסקר והשפיעו</span>
        </div>

        <button type="button" className={css.startButton} onClick={cliskOnStartButton}>
          התחילו כאן
        </button>
      </div>
    </div>
  );
};

export default StartScreen;
