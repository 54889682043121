function transitionClassNames(css: Record<string, string>, prefix?: string) {
  return {
    appear: css[camelCase([prefix, 'appear'])],
    appearActive: css[camelCase([prefix, 'appearActive'])],
    appearDone: css[camelCase([prefix, 'appearDone'])],
    enter: css[camelCase([prefix, 'enter'])],
    enterActive: css[camelCase([prefix, 'enterActive'])],
    enterDone: css[camelCase([prefix, 'enterDone'])],
    exit: css[camelCase([prefix, 'exit'])],
    exitActive: css[camelCase([prefix, 'exitActive'])],
    exitDone: css[camelCase([prefix, 'exitDone'])],
  };
}

function camelCase(strings: (string | undefined)[]) {
  return strings
    .filter(Boolean)
    .join('-')
    .replace(/-(\w)/g, function (match, g) {
      return g.toUpperCase() + '';
    });
}

export { transitionClassNames };
export default transitionClassNames;
